<template>
  <div>
    <a-form :form="form" :layout="formLayout" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="申请撤销的原始清单编号">
            <a-input
              v-decorator="['invt_no', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="身份证">
            <a-input
              v-decorator="['buyer_id_number', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="订购人">
            <a-input
              v-decorator="['buyer_name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="手机号">
            <a-input
              v-decorator="['buyer_telephone', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="申报企业名称">
            <a-input
              v-decorator="['agent_name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="申报企业代码">
            <a-input
              v-decorator="['agent_code', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="原因">
            <a-textarea
              v-decorator="['reason', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="14" style="text-align: center">
          <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
        </a-col>
        <a-col :span="10" v-if="true">
          <a-button @click="handleGoBack">返回</a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { customs_inventory_cancel_patch } from '@/api/customs_order'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      formLayout: 'horizontal',
      id: 0,
      private_tip: '备注：私玥内容此处不展示'
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      if (this.record !== undefined) {
        this.loadEditInfo(this.record)
      }
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
        if (this.record !== undefined) {
          customs_inventory_cancel_patch(values, this.record.id)
            .then((res) => {
              this.handleGoBack()
              console.log(res, '修改成功')
            })
        }
      })
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      console.log('===================', data)
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, [ 'invt_no', 'buyer_id_number', 'buyer_name', 'buyer_telephone', 'agent_code', 'agent_name', 'reason' ])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
